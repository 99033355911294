<div
  class="breadcrumb-wrapper section-padding bg-cover"
  style="background-image: url('../../../assets/img/EngagementModel.png')"
>
  <div class="container">
    <div class="page-heading">
      <h1 class="wow fadeInUp" data-wow-delay=".3s">Engagement Model</h1>
    </div>
  </div>
</div>

<!--<< engagement Section Start >>-->

<section class="about-section section-padding mb-5">
  <div class="line-shape">
    <img src="assets/img/service/line-shape-2.png" alt="shape-img" />
  </div>
  <div class="container">
    <div class="about-wrapper-2 about-wrapper style-2">
      <div class="row">
        <div class="col-lg-6">
          <div class="about-image-items">
            <div class="about-image">
              <div
                class="about-image-1 bg-cover wow fadeInLeft"
                data-wow-delay=".3s"
                style="background-image: url('assets/img/about/about-5.jpg')"
              >
                <div class="about-image-2 wow fadeInUp" data-wow-delay=".5s">
                  <img src="assets/img/about/about-6.jpg" alt="about-img" />
                </div>
              </div>
              <div
                class="experience-text-area wow fadeInUp"
                data-wow-delay=".5s"
                style="
                  visibility: visible;
                  animation-delay: 0.5s;
                  animation-name: fadeInUp;
                "
              >
                <h2><span class="count">20</span>+</h2>
                <h6>Years Of Experience</h6>
                <div class="star">
                  <span class="fas fa-star"></span>
                  <span class="fas fa-star"></span>
                  <span class="fas fa-star"></span>
                  <span class="fas fa-star"></span>
                  <span class="fas fa-star me-2"></span>
                  <span>Trustpoint</span>
                </div>
                <div class="ratting">
                  <span>4.9</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6 mt-5 mt-lg-0">
          <div class="about-content">
            <div class="section-title style-2 mb-2 text-center">
              <span class="wow fadeInUp">The Groot Advantage</span>
            </div>
            <p class="mt-4 mt-md-0 wow fadeInUp" data-wow-delay=".5s">
              At Groot Software Solutions, we are more than just a software
              provider; we are your strategic partner in navigating the
              ever-evolving landscape of digital transformation. Our innovative
              engagement models are designed to empower your business, amplify
              your capabilities, and drive meaningful results. Explore our
              unique approach to collaboration and discover how Groot Software
              Solutions can be the key to unlocking your organization's full
              potential.
            </p>
            <div class="single-tab-items">
              <ul class="nav mb-4 justify-content-between" role="tablist">
                <li
                  class="nav-item wow fadeInUp"
                  data-wow-delay=".3s"
                  role="presentation"
                >
                  <a
                    href="#integrity"
                    data-bs-toggle="tab"
                    class="nav-link active"
                    aria-selected="true"
                    role="tab"
                  >
                    Tailored Solutions
                  </a>
                </li>
                <li
                  class="nav-item wow fadeInUp"
                  data-wow-delay=".5s"
                  role="presentation"
                >
                  <a
                    href="#obejectives"
                    data-bs-toggle="tab"
                    class="nav-link"
                    aria-selected="false"
                    role="tab"
                    tabindex="-1"
                  >
                    Innovation
                  </a>
                </li>
                <li
                  class="nav-item wow fadeInUp"
                  data-wow-delay=".7s"
                  role="presentation"
                >
                  <a
                    href="#excellence"
                    data-bs-toggle="tab"
                    class="nav-link"
                    aria-selected="false"
                    role="tab"
                    tabindex="-1"
                  >
                    Collaboration
                  </a>
                </li>
              </ul>
            </div>
            <div class="tab-content">
              <div
                id="integrity"
                class="tab-pane fade show active"
                role="tabpanel"
              >
                <div class="about-tabs-area">
                  <div class="about-list-items">
                    <ul class="wow fadeInUp" data-wow-delay=".4s">
                      <li>
                        <i class="far fa-check"></i>
                        Customizable engagement models aligned with your
                        specific goals and objectives
                      </li>
                      <li>
                        <i class="far fa-check"></i>
                        Accelerate project delivery and achieve goals with
                        confidence
                      </li>
                      <li>
                        <i class="far fa-check"></i>
                        Clear deliverables, timelines, and outcomes
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div id="obejectives" class="tab-pane fade" role="tabpanel">
                <div class="about-tabs-area">
                  <div class="about-list-items">
                    <ul>
                      <li>
                        <i class="far fa-check"></i>
                        Leverage cutting-edge technologies and pioneering
                        methodologies.
                      </li>
                      <li>
                        <i class="far fa-check"></i>
                        Access to expertise, creativity, and forward-thinking
                        strategies
                      </li>
                      <li>
                        <i class="far fa-check"></i>
                        Drive innovation and push your business forward
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div id="excellence" class="tab-pane fade" role="tabpanel">
                <div class="about-tabs-area">
                  <div class="about-list-items">
                    <ul>
                      <li>
                        <i class="far fa-check"></i>
                        Foster open communication, transparency, and trust
                      </li>
                      <li>
                        <i class="far fa-check"></i>
                        Forge strong partnerships for shared success
                      </li>
                      <li>
                        <i class="far fa-check"></i>
                        Ensure alignment, synergy, and seamless execution
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="woeking-process fix section-padding section-bg-3 mb-5 pb-5">
  <div class="left-shape">
    <img class="index-image" src="assets/img/shape-left.jpg" alt="img" />
  </div>
  <div class="right-shape">
    <img class="index-image" src="assets/img/shape-right.jpg" alt="img" />
  </div>
  <div class="manage-it-wrapper manager-ff">
    <div class="container">
      <div class="section-title text-center">
        <span
          class="color-style wow fadeInUp"
          style="visibility: visible; animation-name: fadeInUp"
          >Our Engagement Models</span
        >
      </div>
      <div class="single-tab-items">
        <ul class="nav mb-4 tab-gap justify-content-between" role="tablist">
          <li
            class="nav-item wow fadeInUp"
            data-wow-delay=".3s"
            role="presentation"
          >
            <a
              href="#eng1"
              data-bs-toggle="tab"
              class="nav-link active"
              aria-selected="true"
              role="tab"
            >
              Project Accelerator
            </a>
          </li>
          <li
            class="nav-item wow fadeInUp"
            data-wow-delay=".5s"
            role="presentation"
          >
            <a
              href="#eng2"
              data-bs-toggle="tab"
              class="nav-link"
              aria-selected="false"
              role="tab"
              tabindex="-1"
            >
              Transformation Partner
            </a>
          </li>
          <li
            class="nav-item wow fadeInUp"
            data-wow-delay=".7s"
            role="presentation"
          >
            <a
              href="#eng3"
              data-bs-toggle="tab"
              class="nav-link"
              aria-selected="false"
              role="tab"
              tabindex="-1"
            >
              Talent Amplifier
            </a>
          </li>
        </ul>
      </div>
      <div class="tab-content">
        <div id="eng1" class="tab-pane fade show active" role="tabpanel">
          <div class="tab-content-items pe-5">
            <div class="row">
              <div
                class="col-xl-3 col-lg-4 col-md-6 wow fadeInUp"
                data-wow-delay=".3s"
                style="
                  visibility: visible;
                  animation-delay: 0.3s;
                  animation-name: fadeInUp;
                "
              >
                <div class="process-items">
                  <span class="process-number">01</span>
                  <div class="content">
                    <div class="icon">
                      <i class="flaticon-service"></i>
                    </div>
                    <h3>Description</h3>
                  </div>
                </div>
                <p class="text-white text-center">
                  Ideal for businesses looking to kickstart new initiatives,
                  launch products, or meet specific project milestones
                </p>
              </div>
              <div
                class="col-xl-3 col-lg-4 col-md-6 wow fadeInUp"
                data-wow-delay=".5s"
                style="
                  visibility: visible;
                  animation-delay: 0.5s;
                  animation-name: fadeInUp;
                "
              >
                <div class="process-items">
                  <span class="process-number">02</span>
                  <div class="content">
                    <div class="icon">
                      <i class="flaticon-talk"></i>
                    </div>
                    <h3>Applicability</h3>
                  </div>
                </div>
                <p class="text-white text-center">
                  For businesses looking to kickstart new initiatives, launch
                  products, or meet specific project milestones
                </p>
              </div>
              <div
                class="col-xl-3 col-lg-4 col-md-6 wow fadeInUp"
                data-wow-delay=".7s"
                style="
                  visibility: visible;
                  animation-delay: 0.7s;
                  animation-name: fadeInUp;
                "
              >
                <div class="process-items">
                  <span class="process-number">03</span>
                  <div class="content">
                    <div class="icon">
                      <i class="flaticon-brainstorming"></i>
                    </div>
                    <h3>Benefits</h3>
                  </div>
                </div>
                <p class="text-white text-center">
                  Accelerate project delivery - Clear deliverables and outcomes
                  - Expert support
                </p>
              </div>
              <div
                class="col-xl-3 col-lg-4 col-md-6 wow fadeInUp"
                data-wow-delay=".9s"
                style="
                  visibility: visible;
                  animation-delay: 0.9s;
                  animation-name: fadeInUp;
                "
              >
                <div class="process-items">
                  <span class="process-number">04</span>
                  <div class="content">
                    <div class="icon">
                      <i class="flaticon-gear"></i>
                    </div>
                    <h3>Pricing Models</h3>
                  </div>
                </div>
                <p class="text-white text-center">
                  Fixed Cost or T&M based on scope and project plan clarity
                </p>
              </div>
            </div>
          </div>
        </div>
        <div id="eng2" class="tab-pane fade" role="tabpanel">
          <div class="tab-content-items pe-5">
            <div class="row">
              <div
                class="col-xl-3 col-lg-4 col-md-6 wow fadeInUp"
                data-wow-delay=".3s"
                style="
                  visibility: visible;
                  animation-delay: 0.3s;
                  animation-name: fadeInUp;
                "
              >
                <div class="process-items">
                  <span class="process-number">01</span>
                  <div class="content">
                    <div class="icon">
                      <i class="flaticon-service"></i>
                    </div>
                    <h3>Description</h3>
                  </div>
                </div>
                <p class="text-white text-center">
                  Take on full ownership of your technology initiatives,
                  providing end-to-end support, guidance, and expertise every
                  step of the way
                </p>
              </div>
              <div
                class="col-xl-3 col-lg-4 col-md-6 wow fadeInUp"
                data-wow-delay=".5s"
                style="
                  visibility: visible;
                  animation-delay: 0.5s;
                  animation-name: fadeInUp;
                "
              >
                <div class="process-items">
                  <span class="process-number">02</span>
                  <div class="content">
                    <div class="icon">
                      <i class="flaticon-talk"></i>
                    </div>
                    <h3>Applicability</h3>
                  </div>
                </div>
                <p class="text-white text-center">
                  For businesses seeking ongoing support and guidance for their
                  technology initiatives
                </p>
              </div>
              <div
                class="col-xl-3 col-lg-4 col-md-6 wow fadeInUp"
                data-wow-delay=".7s"
                style="
                  visibility: visible;
                  animation-delay: 0.7s;
                  animation-name: fadeInUp;
                "
              >
                <div class="process-items">
                  <span class="process-number">03</span>
                  <div class="content">
                    <div class="icon">
                      <i class="flaticon-brainstorming"></i>
                    </div>
                    <h3>Benefits</h3>
                  </div>
                </div>
                <p class="text-white text-center">
                  Full ownership of technology initiatives - End-to-end support,
                  guidance, and expertise - Drive transformative change
                </p>
              </div>
              <div
                class="col-xl-3 col-lg-4 col-md-6 wow fadeInUp"
                data-wow-delay=".9s"
                style="
                  visibility: visible;
                  animation-delay: 0.9s;
                  animation-name: fadeInUp;
                "
              >
                <div class="process-items">
                  <span class="process-number">04</span>
                  <div class="content">
                    <div class="icon">
                      <i class="flaticon-gear"></i>
                    </div>
                    <h3>Pricing Models</h3>
                  </div>
                </div>
                <p class="text-white text-center">Fixed Cost and/or T&M</p>
              </div>
            </div>
          </div>
        </div>
        <div id="eng3" class="tab-pane fade" role="tabpanel">
          <div class="tab-content-items pe-5">
            <div class="row">
              <div
                class="col-xl-3 col-lg-4 col-md-6 wow fadeInUp"
                data-wow-delay=".3s"
                style="
                  visibility: visible;
                  animation-delay: 0.3s;
                  animation-name: fadeInUp;
                "
              >
                <div class="process-items">
                  <span class="process-number">01</span>
                  <div class="content">
                    <div class="icon">
                      <i class="flaticon-service"></i>
                    </div>
                    <h3>Select Services</h3>
                  </div>
                </div>
                <p class="text-white text-center">
                  Access to a diverse pool of skilled professionals who can
                  seamlessly integrate into your organization
                </p>
              </div>
              <div
                class="col-xl-3 col-lg-4 col-md-6 wow fadeInUp"
                data-wow-delay=".5s"
                style="
                  visibility: visible;
                  animation-delay: 0.5s;
                  animation-name: fadeInUp;
                "
              >
                <div class="process-items">
                  <span class="process-number">02</span>
                  <div class="content">
                    <div class="icon">
                      <i class="flaticon-talk"></i>
                    </div>
                    <h3>Schedule Meeting</h3>
                  </div>
                </div>
                <p class="text-white text-center">
                  For businesses needing additional resources or expertise to
                  supplement their existing team
                </p>
              </div>
              <div
                class="col-xl-3 col-lg-4 col-md-6 wow fadeInUp"
                data-wow-delay=".7s"
                style="
                  visibility: visible;
                  animation-delay: 0.7s;
                  animation-name: fadeInUp;
                "
              >
                <div class="process-items">
                  <span class="process-number">03</span>
                  <div class="content">
                    <div class="icon">
                      <i class="flaticon-brainstorming"></i>
                    </div>
                    <h3>Benefits</h3>
                  </div>
                </div>
                <p class="text-white text-center">
                  Access to a diverse pool of skilled professionals - Seamless
                  integration into your organization - Tackle complex challenges
                  with ease
                </p>
              </div>
              <div
                class="col-xl-3 col-lg-4 col-md-6 wow fadeInUp"
                data-wow-delay=".9s"
                style="
                  visibility: visible;
                  animation-delay: 0.9s;
                  animation-name: fadeInUp;
                "
              >
                <div class="process-items">
                  <span class="process-number">04</span>
                  <div class="content">
                    <div class="icon">
                      <i class="flaticon-gear"></i>
                    </div>
                    <h3>Pricing Models</h3>
                  </div>
                </div>
                <p class="text-white text-center">T&M</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="cta-banner-2 wow fadeInUp"
      data-wow-delay=".4s"
      style="
        visibility: visible;
        animation-delay: 0.4s;
        animation-name: fadeInUp;
      "
    >
      <div class="cta-items">
        <div class="content">
          <p>Ready to Get</p>
          <h6>Consultations to Our Expertise</h6>
        </div>
      </div>
      <a [routerLink]="['/enquiry']" class="theme-btn bg-white">
        <span>
          Explore More
          <i class="fas fa-chevron-right"></i>
        </span>
      </a>
    </div>
  </div>
</section>

<section class="manage-it-section fix section-padding pt-0">
  <div class="container">
    <div class="manage-it-wrapper">
      <div class="row justify-content-between">
        <div class="col-lg-12">
          <div class="manage-it-content">
            <div class="section-title">
              <span class="wow fadeInUp">Why Choose Groot? </span>
            </div>
            <div class="row">
              <div class="single-tab-items mt-4 mt-md-0 col-lg-8">
                <ul class="nav mb-4 tab-gap" role="tablist">
                  <li
                    class="nav-item wow fadeInUp"
                    data-wow-delay=".3s"
                    role="presentation"
                  >
                    <a
                      href="#approach"
                      data-bs-toggle="tab"
                      class="nav-link active"
                      aria-selected="true"
                      role="tab"
                    >
                      Proven Track Record
                    </a>
                  </li>
                  <li
                    class="nav-item wow fadeInUp"
                    data-wow-delay=".5s"
                    role="presentation"
                  >
                    <a
                      href="#project"
                      data-bs-toggle="tab"
                      class="nav-link"
                      aria-selected="false"
                      role="tab"
                      tabindex="-1"
                    >
                      Customer-Centric Approach
                    </a>
                  </li>
                  <li
                    class="nav-item wow fadeInUp"
                    data-wow-delay=".7s"
                    role="presentation"
                  >
                    <a
                      href="#advisory"
                      data-bs-toggle="tab"
                      class="nav-link"
                      aria-selected="false"
                      role="tab"
                      tabindex="-1"
                    >
                      Continuous Innovation
                    </a>
                  </li>
                </ul>
                <div class="tab-content">
                  <div
                    id="approach"
                    class="tab-pane fade show active"
                    role="tabpanel"
                  >
                    <div class="tab-content-items pe-5">
                      <p class="wow fadeInUp" data-wow-delay=".3s">
                        With years of experience and a proven track record of
                        success, Groot Software Solutions is a trusted partner
                        you can rely on to deliver results.
                      </p>
                    </div>
                  </div>
                  <div id="project" class="tab-pane fade" role="tabpanel">
                    <div class="tab-content-items pe-5">
                      <p>
                        Groot Software Solutions has been certified as a Great
                        Place to Work 2024, recognized for building a High-Trust
                        and High-Performance Culture.
                      </p>
                    </div>
                  </div>
                  <div id="advisory" class="tab-pane fade" role="tabpanel">
                    <div class="tab-content-items pe-5">
                      <p>
                        We are committed to staying ahead of the curve,
                        continually investing in research, development, and
                        innovation to deliver solutions that drive real value
                        and competitive advantage.
                      </p>
                    </div>
                  </div>
                </div>
                <div class="mt-5">
                  <p class=" ">
                    Ready to embark on your digital transformation journey?
                    Contact us today to learn more about our engagement models
                    and discover how Groot Software Solutions can help you
                    achieve your goals and unlock new opportunities for growth,
                    innovation, and success
                  </p>
                </div>
              </div>

              <div class="col-lg-4">
                <p
                  _ngcontent-pnb-c52=""
                  data-wow-delay=".4s"
                  class="mt-4 mt-md-0 wow fadeInUp me-4 full-wd"
                  style="
                    visibility: visible;
                    animation-delay: 0.4s;
                    animation-name: fadeInUp;
                  "
                >
                  Top American businesses accelerate their tech roadmaps with
                  us. See how we can help you.
                </p>
                <a
                  _ngcontent-yjy-c74=""
                  class="theme-btn theme-btn-2 col-6 mt-4"
                  ng-reflect-router-link="/enquiry"
                  href="/enquiry"
                  ><span _ngcontent-yjy-c74="">
                    contact us
                    <i
                      _ngcontent-yjy-c74=""
                      class="fas fa-chevron-right"
                    ></i></span
                ></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
