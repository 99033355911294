<div class="breadcrumb-wrapper section-padding bg-cover" style="background-image: url('assets/img/breadcrumb.jpg');">
  <div class="container">
    <div class="page-heading">
      <h1 class="wow fadeInUp" data-wow-delay=".3s">Contact Us</h1>
    </div>
  </div>
</div>

<!--<< Contact Section Start >>-->
<section class="contact-main-area fix section-padding">
  <div class="container">
    <div class="contact-main-wrapper">
      <div class="row g-4">
        <div class="col-lg-6">
          <div class="contact-content">
            <div class="section-title mb-2">
              <span class="wow fadeInUp">Get In Touch</span>
              <h2 class="wow fadeInUp" data-wow-delay=".3s">Contact Us</h2>
            </div>
            <p class="mt-4 mt-md-0 wow fadeInUp" data-wow-delay=".4s">
              Top American businesses accelerate their tech roadmaps with us. See how we can help you.
            </p>
            <div class="row g-4 mt-3">
              <div class="col-lg-6 wow fadeInUp" data-wow-delay=".3s">
                <div class="info-items">
                  <div class="icon">
                    <i class="fas fa-map-marker-alt"></i>
                  </div>
                  <div class="content">
                    <h5>Location</h5>
                    <p>
                      Sushma Infinium, 3rd Floor, Zirakpur(Chandigarh), Punjab, 140603
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 wow fadeInUp" data-wow-delay=".5s">
                <div class="info-items">
                  <div class="icon">
                    <i class="far fa-phone"></i>
                  </div>
                  <div class="content">
                    <h5>Phone</h5>
                    <a href="tel:01762460300">0176-2460300 </a> <br>

                  </div>
                </div>
              </div>
              <div class="col-lg-6 wow fadeInUp" data-wow-delay=".7s">
                <div class="info-items">
                  <div class="icon">
                    <i class="fal fa-envelope"></i>
                  </div>
                  <div class="content">
                    <h5>Email</h5>
                    <a href="mailto:info@grootsoftwares.com" class="link">info&#64;grootsoftwares.com</a>

                  </div>
                </div>
              </div>
              <div class="col-lg-6 wow fadeInUp" data-wow-delay=".9s">
                <div class="info-items">
                  <div class="icon">
                    <i class="fad fa-share-alt"></i>
                  </div>
                  <div class="content">
                    <h5>Social</h5>
                    <div class="social-icon d-flex align-items-center">
                      <a target="_blank" href="https://ln.run/xUsXc"><i class="fab fa-facebook-f"></i></a>
                      <a target="_blank" href="https://ln.run/H945m"><i class="fab fa-linkedin-in"></i></a>

                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="contact-image wow fadeInUp" data-wow-delay=".4s">
              <img src="assets/img/contact.jpg" alt="img">
            </div>
          </div>
        </div>
        <div class="col-lg-6 mt-5 mt-lg-0">
          <div class="contact-form-items">
            <div class="contact-title">
              <h3 class="wow fadeInUp" data-wow-delay=".3s">Fill Up The Form</h3>
              <p class="wow fadeInUp" data-wow-delay=".5s">Your email address will not be published. Required fields are
                marked *</p>
            </div>

            <form [formGroup]="enquiryForm" (ngSubmit)="onSubmit()">
              <div class="row g-4">
                <div class="col-lg-12 wow fadeInUp" data-wow-delay=".3s">
                  <div class="form-clt">
                    <input type="text" formControlName="firstName" placeholder="Your Name*">
                  </div>
                  <div *ngIf="submitted && f.firstName.errors " class="text-danger">
                    <div *ngIf="f.firstName.errors.required">Name is required</div>
                    <div *ngIf="!f.firstName.errors.required && f.firstName.errors.notOnlyWhitespace">Name is required</div>

                  </div>
                </div>
                <div class="col-lg-12 wow fadeInUp" data-wow-delay=".5s">
                  <div class="form-clt">
                    <input type="email" formControlName="userEmail" placeholder="Email Address*">
                  </div>
                  <div *ngIf="submitted && f.userEmail.errors" class="text-danger">
                    <div *ngIf="f.userEmail.errors.required">Email is required</div>
                    <div *ngIf="f.userEmail.errors.email && !f.userEmail.errors.required">Invalid email</div>
                  </div>

                </div>
                <div class="col-lg-12 wow fadeInUp" data-wow-delay=".7s">
                  <div class="form-clt">
                    <textarea formControlName="message" placeholder="Enter Your Message Here*"></textarea>
                  </div>
                  <div *ngIf="submitted && f.message.errors" class="text-danger">
                    <div *ngIf="f.message.errors.required">Message is required</div>
                    <div *ngIf="!f.message.errors.required && f.message.errors.notOnlyWhitespace">Message is required</div>

                  </div>
                </div>
                <div class="col-lg-12 wow fadeInUp" data-wow-delay=".7s">
                  <div class="form-clt">

                    <re-captcha (resolved)="resolved($event)" siteKey="{{environment.recaptcha.siteKey }}"
                      formControlName="recaptcha"></re-captcha>

                  </div>
                  <div *ngIf="submitted && f.recaptcha.errors" class="text-danger">
                    <div *ngIf="f.recaptcha.errors.required">Please verify you are human
                    </div>
                  </div>

                </div>



                <div class="col-lg-6 wow fadeInUp" data-wow-delay=".8s">
                  <button type="submit" class="theme-btn" [disabled]="disabled"><span><i
                        class="fal fa-paper-plane"></i>Get In
                      Touch</span></button>
                </div>
              </div>
            </form>

          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<!--<< Map Section Start >>-->
<div class="map-section">
  <div class="google-map wow fadeInUp" data-wow-delay=".7s">
    <iframe
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3432.8137500730672!2d76.82181777557673!3d30.639206174629575!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390f954fde0a9b03%3A0x9edcbc465da878f9!2sGrootNet%20Software%20Solutions%20Pvt.%20Ltd.!5e0!3m2!1sen!2sin!4v1713333525738!5m2!1sen!2sin"
      style="border:0;" allowfullscreen="" loading="lazy"></iframe>
  </div>
</div>