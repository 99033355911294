<div
  class="breadcrumb-wrapper section-padding bg-cover"
  style="background-image: url('../../../assets/img/bg-case-study.jpg')"
>
  <div class="container">
    <div class="page-heading">
      <h1 class="wow fadeInUp" data-wow-delay=".3s">Case Study</h1>
    </div>
  </div>
</div>

<!--<< About Section Start >>-->

<section class="case-study-2 fix section-padding pt-5">
  <div class="container">
    <div class="section-title style-2 text-center">
      <span class="wow fadeInUp">Turning dreams into digital reality</span>
      <h2 class="wow fadeInUp" data-wow-delay=".3s">
        Crafting success stories that
        <span> inspire and drive</span>
        businesses forward
      </h2>
    </div>
  </div>
  <div class="case-study-wrapper">
    <!-- <div class="case-studies-carousel-active-2">
      <div class="case-study-items">
        <div class="case-study-image">
          <img src="assets/img/case-studies/05.jpg" alt="case-study-img" />
        </div>
        <div class="case-study-content">
          <h3>
            <a
              [routerLink]="['/boosting-financial-data-efficiency']"
              routerLinkActive="active"
              >Boosting Financial Data Efficiency</a
            >
          </h3>
          <p>Financial Services</p>
          <a
            [routerLink]="['/boosting-financial-data-efficiency']"
            routerLinkActive="active"
            class="icon"
          >
            <i class="fal fa-arrow-right"></i>
          </a>
        </div>
      </div>
      <div class="case-study-items">
        <div class="case-study-image">
          <img src="assets/img/case-studies/06.jpg" alt="case-study-img" />
        </div>
        <div class="case-study-content">
          <h3>
            <a
              [routerLink]="['/unlocking-digital-creativity']"
              routerLinkActive="active"
              >Unlocking Digital Creativity</a
            >
          </h3>
          <p>Digital Media & Entertainment</p>
          <a
            [routerLink]="['/unlocking-digital-creativity']"
            routerLinkActive="active"
            class="icon"
          >
            <i class="fal fa-arrow-right"></i>
          </a>
        </div>
      </div>
      <div class="case-study-items">
        <div class="case-study-image">
          <img src="assets/img/case-studies/07.jpg" alt="case-study-img" />
        </div>
        <div class="case-study-content">
          <h3>
            <a
              [routerLink]="['/championing-healthcare-evolution']"
              routerLinkActive="active"
            >
              Championing Healthcare Evolution
            </a>
          </h3>
          <p>Healthcare</p>
          <a
            [routerLink]="['/championing-healthcare-evolution']"
            routerLinkActive="active"
            class="icon"
          >
            <i class="fal fa-arrow-right"></i>
          </a>
        </div>
      </div>

      <div class="case-study-items">
        <div class="case-study-image">
          <img src="assets/img/case-studies/08.jpg" alt="case-study-img" />
        </div>
        <div class="case-study-content">
          <h3>
            <a
              [routerLink]="[
                '/revolutionizing-fintech-with-groot-software-solutions'
              ]"
              routerLinkActive="active"
            >
              Revolutionizing Fintech with Groot Software Solutions
            </a>
          </h3>
          <p>Fintech</p>
          <a
            [routerLink]="[
              '/revolutionizing-fintech-with-groot-software-solutions'
            ]"
            routerLinkActive="active"
            class="icon"
          >
            <i class="fal fa-arrow-right"></i>
          </a>
        </div>
      </div>
    </div> -->
    <div class="case-studies-carousel-active-2">
      <div class="case-study-items" *ngFor="let study of caseStudies; let i=index">
        <div class="case-study-image">
          <img [src]="study.imageSrc" alt="case-study-img" />
        </div>
        <div class="case-study-content">
          <h3><a [routerLink]="[study.routerLink]">{{ study.title }}</a></h3>
          <p>{{ study.category }}</p>
          <a [routerLink]="[study.routerLink]" class="icon">
            <i class="fal fa-arrow-right"></i>
          </a>
        </div>
      </div>
    </div>
    
  </div>
</section>

<!--<< Brand Section Start >>-->

<section
  class="contact-section-2 fix section-padding bg-cover"
  style="background-image: url('assets/img/contact-bg.jpg')"
>
  <div class="contact-image">
    <img src="assets/img/contact-2.png" alt="contact-img" />
  </div>
  <div class="container">
    <div class="contact-wrapper-2">
      <div class="row align-items-center">
        <div class="col-lg-6">
          <div class="contact-content">
            <div class="section-title style-2">
              <span class="wow fadeInUp">Contact Us</span>
              <h2 class="wow fadeInUp" data-wow-delay=".3s">
                Ready to start the conversation?<br /><span class="weare">
                  We're all ears
                </span>
              </h2>
            </div>
            <p class="mt-4 mt-md-0 wow fadeInUp" data-wow-delay=".5s">
              Whether it's a brainstorm, a question, or a bold idea waiting to
              be unleashed, we're eager to dive into the discussion with you.
              Please fill the form or send us an email
              info&#64;grootsoftwares.com
            </p>
            <div class="contact-info-area">
              <div
                class="contact-info-items mb-4 wow fadeInUp"
                data-wow-delay=".7s"
              >
                <div class="icon">
                  <i class="far fa-phone"></i>
                </div>
                <div class="content">
                  <p>Call For Inquiry</p>
                  <h3><a href="tel:+01762460300">0176-2460300</a></h3>
                </div>
              </div>
              <div
                class="contact-info-items mb-4 wow fadeInUp"
                data-wow-delay=".8s"
              >
                <div class="icon">
                  <i class="fal fa-envelope"></i>
                </div>
                <div class="content">
                  <p>Send Us Email</p>
                  <h3>
                    <a href="mailto:info@grootsoftwares.com"
                      >info&#64;grootsoftwares.com</a
                    >
                  </h3>
                </div>
              </div>
              <div class="contact-info-items wow fadeInUp" data-wow-delay=".9s">
                <div class="icon">
                  <i class="fas fa-map-marker-alt"></i>
                </div>
                <div class="content">
                  <p>Location</p>
                  <h3>
                    Sushma Infinium, 3rd Floor, Zirakpur(Chandigarh), Punjab,
                    140603
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6 mt-5 mt-lg-0">
          <div class="contact-box">
            <div class="contact-title">
              <h3 class="wow fadeInUp" data-wow-delay=".3s">
                Please send a message to get the conversation started
              </h3>
              <p class="wow fadeInUp" data-wow-delay=".5s">
                Let's turn potential into action
              </p>
            </div>
            <div class="contact-form-items">
              <form [formGroup]="enquiryForm" (ngSubmit)="onSubmit()">
                <div class="row g-3">
                  <div class="col-lg-6 wow fadeInUp" data-wow-delay=".3s">
                    <div class="form-clt">
                      <input
                        type="text"
                        placeholder="First Name*"
                        formControlName="firstName"
                      />
                      <div class="icon">
                        <i class="far fa-user"></i>
                      </div>
                    </div>
                    <div
                      *ngIf="submitted && f.firstName.errors?.required"
                      class="text-danger"
                    >
                      First name is required
                    </div>
                    <div
                      *ngIf="
                        submitted &&
                        !f.firstName.errors?.required &&
                        f.firstName.errors?.notOnlyWhitespace
                      "
                      class="text-danger"
                    >
                      First name is required
                    </div>
                  </div>
                  <div class="col-lg-6 wow fadeInUp" data-wow-delay=".5s">
                    <div class="form-clt">
                      <input
                        type="text"
                        placeholder="Last Name*"
                        formControlName="lastName"
                      />
                      <div class="icon">
                        <i class="far fa-user"></i>
                      </div>
                    </div>
                    <div
                      *ngIf="submitted && f.lastName.errors?.required"
                      class="text-danger"
                    >
                      Last name is required
                    </div>
                    <div
                      *ngIf="
                        submitted &&
                        !f.lastName.errors?.required &&
                        f.lastName.errors?.notOnlyWhitespace
                      "
                      class="text-danger"
                    >
                      Last name is required
                    </div>
                  </div>
                  <div class="col-lg-6 wow fadeInUp" data-wow-delay=".3s">
                    <div class="form-clt">
                      <input
                        type="email"
                        placeholder="Company Email*"
                        formControlName="userEmail"
                      />
                      <div class="icon">
                        <i class="far fa-envelope"></i>
                      </div>
                    </div>
                    <div *ngIf="submitted && f.userEmail.errors">
                      <div
                        *ngIf="f.userEmail.errors.required"
                        class="text-danger"
                      >
                        Company email is required
                      </div>
                      <div
                        *ngIf="
                          f.userEmail.errors.email &&
                          !f.userEmail.errors.required
                        "
                        class="text-danger"
                      >
                        Invalid email
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 wow fadeInUp" data-wow-delay=".5s">
                    <div class="form-clt">
                      <input
                        type="text"
                        placeholder="Company Number*"
                        formControlName="phoneNumber"
                      />
                      <div class="icon">
                        <i class="far fa-phone"></i>
                      </div>
                    </div>
                    <div
                      *ngIf="submitted && f.phoneNumber.errors?.required"
                      class="text-danger"
                    >
                      Company number is required
                    </div>
                    <div
                      *ngIf="
                        submitted &&
                        !f.phoneNumber.errors?.required &&
                        f.phoneNumber.errors?.notOnlyWhitespace
                      "
                      class="text-danger"
                    >
                      Company number is required
                    </div>
                    <div *ngIf="f.phoneNumber.invalid">
                      <div
                        *ngIf="f.phoneNumber.errors?.pattern"
                        class="text-danger"
                      >
                        Enter only number.
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-12 wow fadeInUp" data-wow-delay=".5s">
                    <div class="form-clt">
                      <input
                        type="text"
                        placeholder="Company Name*"
                        formControlName="companyName"
                      />
                    </div>
                    <div
                      *ngIf="submitted && f.companyName.errors?.required"
                      class="text-danger"
                    >
                      Company name is required
                    </div>
                    <div
                      *ngIf="
                        submitted &&
                        f.companyName.errors.notOnlyWhitespace &&
                        !f.companyName.errors?.required
                      "
                      class="text-danger"
                    >
                      Company name is required
                    </div>
                  </div>

                  <div class="col-lg-12 wow fadeInUp" data-wow-delay=".5s">
                    <div class="form-clt">
                      <re-captcha
                        (resolved)="resolved($event)"
                        siteKey="{{ environment.recaptcha.siteKey }}"
                        formControlName="recaptcha"
                      ></re-captcha>
                    </div>
                    <div
                      *ngIf="submitted && f.recaptcha.errors"
                      class="text-danger"
                    >
                      <div *ngIf="f.recaptcha.errors.required">
                        Please verify you are human
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-12 wow fadeInUp" data-wow-delay=".4s">
                    <button
                      type="submit"
                      class="theme-btn theme-btn-2"
                      [disabled]="disabled"
                    >
                      <span>
                        Submit
                        <i class="fas fa-chevron-right"></i>
                      </span>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
