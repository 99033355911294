<section class="hero-section hero-3">
  <div class="slider-button">
    <div class="hero-nav-prev"><i class="fal fa-long-arrow-left"></i></div>
    <div class="hero-nav-next"><i class="fal fa-long-arrow-right"></i></div>
  </div>
  <div class="hero-slider-active-2">
    <div class="single-slide">
      <div
        class="slide-bg bg-cover"
        style="background-image: url('assets/img/hero/hero-6.jpg')"
      >
        <div class="container">
          <div class="hero-content content-hero-bg">
            <h4
              class="fs-lg animated"
              data-animation-in="fadeInUp"
              data-delay-in="0.3"
            >
              Innovate. Integrate. Accelerate
            </h4>
            <h1
              class="fs-lg animated"
              data-animation-in="fadeInUp"
              data-delay-in="0.5"
            >
              Re-Engineering Traditional Businesses
            </h1>
            <div
              class="hero-button fs-lg animated"
              data-animation-in="fadeInUp"
              data-delay-in="0.7"
            >
              <!-- Button trigger modal -->
              <a
                class="theme-btn theme-btn-2 hover-white"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
              >
                <span _ngcontent-maw-c47=""
                  ><i _ngcontent-maw-c47="" class="fas fa-envelope me-2"></i
                  >Connect with Expert</span
                >
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<!--<< Service Section Start >>-->
<section class="service-section section-bg-4 fix section-padding">
  <div class="container">
    <div class="title-section-area">
      <div class="section-title style-2">
        <span class="wow fadeInUp">What We Do </span>
        <h2 class="wow fadeInUp" data-wow-delay=".3s">
          What We’re Offering To Our Customers
        </h2>
      </div>
      <p class="wow fadeInUp" data-wow-delay=".5s">
        Explore our range of offerings, continuously adapting to meet the
        dynamic requirements of our clients.
      </p>
    </div>
    <!-- <div class="service-wrapper-3">
      <div class="service-box-items style-2 wow fadeInUp" data-wow-delay=".3s">
        <div class="icon">
          <i class="flaticon-coding"></i>
        </div>
        <div class="content">
          <h6>
            <a
              [routerLink]="['/strategy-and-transformation']"
              routerLinkActive="active"
            >
              Strategy and Transformation
            </a>
          </h6>
        </div>
      </div>
      <div class="service-box-items style-2 wow fadeInUp" data-wow-delay=".5s">
        <div class="icon">
          <i class="flaticon-server"></i>
        </div>
        <div class="content">
          <h6>
            <a
              [routerLink]="['/applications-and-technology']"
              routerLinkActive="active"
            >
              Applications and Technology
            </a>
          </h6>
        </div>
      </div>
      <div class="service-box-items style-2 wow fadeInUp" data-wow-delay=".7s">
        <div class="icon">
          <i class="flaticon-data-science"></i>
        </div>
        <div class="content">
          <h6>
            <a [routerLink]="['/core-engineering']" routerLinkActive="active">
              Core <br />Engineering
            </a>
          </h6>
        </div>
      </div>
      <div class="service-box-items style-2 wow fadeInUp" data-wow-delay=".8s">
        <div class="icon">
          <i class="flaticon-technology-1"></i>
        </div>
        <div class="content">
          <h6>
            <a
              [routerLink]="['/business-&-digitalsolutions']"
              routerLinkActive="active"
            >
              Business & Digital Solutions
            </a>
          </h6>
        </div>
      </div>
    </div> -->

    <div class="service-wrapper-3">
      <div
        class="service-box-items style-2 wow fadeInUp"
        *ngFor="let service of serviceBoxes; let i = index"
        [attr.data-wow-delay]="0.3 + i * 0.2 + 's'"
      >
        <div class="icon">
          <i class="{{ service.iconClass }}"></i>
        </div>
        <div class="content">
          <h6>
            <a [routerLink]="[service.routerLink]" routerLinkActive="active">
              {{ service.title }}
            </a>
          </h6>
        </div>
      </div>
    </div>

    <div
      class="service-text-area text-center mt-5 wow fadeInUp"
      data-wow-delay=".4s"
    >
      <h5>
        Get ahead of the game.<br />
        <a
          [routerLink]="['/services']"
          routerLinkActive="active"
          class="link-btn link-btn-2 mt-2"
        >
          <span>View All Services </span>
          <i class="fal fa-long-arrow-right"></i>
        </a>
      </h5>
    </div>
  </div>
</section>

<!--<< About Section Start >>-->
<section class="about-section section-padding">
  <div class="line-shape">
    <img src="assets/img/service/line-shape-2.png" alt="shape-img" />
  </div>
  <div class="container">
    <div class="about-wrapper-2 about-wrapper style-2">
      <div class="row">
        <div class="col-lg-6">
          <div class="about-image-items">
            <div class="about-image">
              <div
                class="about-image-1 bg-cover wow fadeInLeft"
                data-wow-delay=".3s"
                style="background-image: url('assets/img/about/about-5.jpg')"
              >
                <div class="about-image-2 wow fadeInUp" data-wow-delay=".5s">
                  <img src="assets/img/about/about-6.jpg" alt="about-img" />
                </div>
              </div>
              <div
                class="experience-text-area wow fadeInUp"
                data-wow-delay=".5s"
                style="
                  visibility: visible;
                  animation-delay: 0.5s;
                  animation-name: fadeInUp;
                "
              >
                <h2><span class="count">20</span>+</h2>
                <h6>Years Of Experience</h6>
                <div class="star">
                  <span class="fas fa-star"></span>
                  <span class="fas fa-star"></span>
                  <span class="fas fa-star"></span>
                  <span class="fas fa-star"></span>
                  <span class="fas fa-star me-2"></span>
                  <span>Trustpoint</span>
                </div>
                <div class="ratting">
                  <span>4.9</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6 mt-5 mt-lg-0">
          <div class="about-content">
            <div class="section-title style-2">
              <span class="wow fadeInUp">Why Groot Software Solutions</span>
              <h2 class="wow fadeInUp" data-wow-delay=".3s">
                WHAT WE’RE <span>OFFERING</span> TO OUR CUSTOMERS
              </h2>
            </div>
            <p class="mt-4 mt-md-0 wow fadeInUp" data-wow-delay=".5s">
              A Trusted Partner for Fit-For-Purpose Solutions for Tech &
              Non-Tech Businesses, Big and Small. Groot is a one-stop
              destination for digital transformation. Accelerate your digital
              presence by partnering with the best application software
              development company in the USA.
            </p>
            <div class="single-tab-items">
              <ul class="nav mb-4 justify-content-between" role="tablist">
                <li
                  class="nav-item wow fadeInUp"
                  data-wow-delay=".3s"
                  role="presentation"
                >
                  <a
                    href="#integrity"
                    data-bs-toggle="tab"
                    class="nav-link active"
                    aria-selected="true"
                    role="tab"
                  >
                    Custom Solutions
                  </a>
                </li>
                <li
                  class="nav-item wow fadeInUp"
                  data-wow-delay=".5s"
                  role="presentation"
                >
                  <a
                    href="#obejectives"
                    data-bs-toggle="tab"
                    class="nav-link"
                    aria-selected="false"
                    role="tab"
                    tabindex="-1"
                  >
                    Top 1% Talent
                  </a>
                </li>
                <li
                  class="nav-item wow fadeInUp"
                  data-wow-delay=".7s"
                  role="presentation"
                >
                  <a
                    href="#excellence"
                    data-bs-toggle="tab"
                    class="nav-link"
                    aria-selected="false"
                    role="tab"
                    tabindex="-1"
                  >
                    Support & Maintenance
                  </a>
                </li>
              </ul>
            </div>
            <!------------------------------------------- updated code for video --------------------------------------------------------->
            <div class="tab-content">
              <div
                id="integrity"
                class="tab-pane fade show active"
                role="tabpanel"
              >
                <div class="about-tabs-area">
                  <div class="about-list-items">
                    <div class="video-image wow fadeInUp" data-wow-delay=".3s">
                      <div class="video-overlay">
                        <img
                          src="assets/img/about/video.jpg"
                          alt="img"
                          class="open-popup"
                          data-video="assets/img/hero/my-video-bg.mp4"
                        />
                        <div class="video-icon video-play">
                          <i class="fas fa-play"></i>
                          <!-- Assuming you're using FontAwesome for icons -->
                        </div>
                      </div>
                    </div>

                    <ul class="wow fadeInUp" data-wow-delay=".4s">
                      <li>
                        <i class="far fa-check"></i>Client-specific development
                      </li>
                      <li>
                        <i class="far fa-check"></i>Agile methodology used
                      </li>
                      <li>
                        <i class="far fa-check"></i>Scalable, flexible solutions
                      </li>
                    </ul>
                  </div>
                  <div class="about-author">
                    <div class="author-image wow fadeInUp" data-wow-delay=".3s">
                      <img
                        src="assets/img/about/client-2.png"
                        alt="author-img"
                      />
                      <div class="content">
                        <h6>Let's Discuss Your Project</h6>
                      </div>
                    </div>
                    <div
                      class="author-contact wow fadeInUp"
                      data-wow-delay=".5s"
                    >
                      <div class="icon">
                        <img src="assets/img/contact.png" alt="img" />
                      </div>
                      <div class="content">
                        <p>Phone:</p>
                        <h6><a href="tel:+01762460300">0176-2460300</a></h6>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="video-popup-modal">
                  <div class="custom-video-box">
                    <!-- <button type="button" class="btn-close close-modals" aria-label="Close"></button> -->
                    <button type="button" class="close">&times;</button>
                    <video
                      class="video-btn ripple video-player"
                      controls
                      disablepictureinpicture
                      controlslist="nodownload noplaybackrate"
                      id="videoPlayer1"
                    >
                      <source
                        src="../../../assets/img/hero/my-video-bg.mp4"
                        type="video/mp4"
                      />
                    </video>
                  </div>
                </div>
              </div>

              <div id="obejectives" class="tab-pane fade" role="tabpanel">
                <div class="about-tabs-area">
                  <div class="about-list-items">
                    <div class="video-image wow fadeInUp" data-wow-delay=".3s">
                      <div class="video-overlay">
                        <img
                          src="assets/img/about/video-3.png"
                          alt="img"
                          class="open-popup"
                          data-video="assets/img/hero/my-video-bg.mp4"
                        />
                        <div class="video-icon video-play2">
                          <i class="fas fa-play"></i>
                          <!-- Assuming you're using FontAwesome for icons -->
                        </div>
                      </div>
                    </div>

                    <ul class="wow fadeInUp" data-wow-delay=".4s">
                      <li>
                        <i class="far fa-check"></i>
                        Cutting-edge expertise
                      </li>
                      <li>
                        <i class="far fa-check"></i>
                        Diverse project experience
                      </li>
                      <li>
                        <i class="far fa-check"></i>
                        Innovative problem-solving
                      </li>
                    </ul>
                  </div>
                  <div class="about-author">
                    <div class="author-image wow fadeInUp" data-wow-delay=".3s">
                      <img
                        src="assets/img/about/client-2.png"
                        alt="author-img"
                      />
                      <div class="content">
                        <h6>Let's Discuss Your Project</h6>
                      </div>
                    </div>
                    <div
                      class="author-contact wow fadeInUp"
                      data-wow-delay=".5s"
                    >
                      <div class="icon">
                        <img src="assets/img/contact.png" alt="img" />
                      </div>
                      <div class="content">
                        <p>Phone:</p>
                        <h6><a href="tel:+01762460300">0176-2460300</a></h6>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="video-popup-modal">
                  <div class="custom-video-box">
                    <!-- <button type="button" class="btn-close close-modals" aria-label="Close"></button> -->
                    <button type="button" class="close">&times;</button>
                    <video
                      class="video-btn ripple video-player"
                      controls
                      disablepictureinpicture
                      controlslist="nodownload noplaybackrate"
                      id="videoPlayer2"
                    >
                      <source
                        src="../../../assets/img/hero/my-video-bg.mp4"
                        type="video/mp4"
                      />
                    </video>
                  </div>
                </div>
              </div>

              <div id="excellence" class="tab-pane fade" role="tabpanel">
                <div class="about-tabs-area">
                  <div class="about-list-items">
                    <div class="video-image wow fadeInUp" data-wow-delay=".3s">
                      <div class="video-overlay">
                        <img
                          src="assets/img/about/video-2.png"
                          alt="img"
                          class="open-popup"
                          data-video="assets/img/hero/my-video-bg.mp4"
                        />
                        <div class="video-icon video-play3">
                          <i class="fas fa-play"></i>
                          <!-- Assuming you're using FontAwesome for icons -->
                        </div>
                      </div>
                    </div>

                    <ul class="wow fadeInUp" data-wow-delay=".4s">
                      <li>
                        <i class="far fa-check"></i> 24x7 continuous assistance
                      </li>
                      <li>
                        <i class="far fa-check"></i> Proactive 99.9% upkeep
                      </li>
                      <li>
                        <i class="far fa-check"></i> Swift issue resolution
                      </li>
                    </ul>
                  </div>
                  <div class="about-author">
                    <div class="author-image wow fadeInUp" data-wow-delay=".3s">
                      <img
                        src="assets/img/about/client-2.png"
                        alt="author-img"
                      />
                      <div class="content">
                        <h6>Let's Discuss Your Project</h6>
                      </div>
                    </div>
                    <div
                      class="author-contact wow fadeInUp"
                      data-wow-delay=".5s"
                    >
                      <div class="icon">
                        <img src="assets/img/contact.png" alt="img" />
                      </div>
                      <div class="content">
                        <p>Phone:</p>
                        <h6><a href="tel:+01762460300">0176-2460300</a></h6>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="video-popup-modal">
                  <div class="custom-video-box">
                    <!-- <button type="button" class="btn-close close-modals" aria-label="Close"></button> -->
                    <button type="button" class="close">&times;</button>
                    <video
                      class="video-btn ripple video-player"
                      controls
                      disablepictureinpicture
                      controlslist="nodownload noplaybackrate"
                      id="videoPlayer3"
                    >
                      <source
                        src="../../../assets/img/hero/my-video-bg.mp4"
                        type="video/mp4"
                      />
                    </video>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<!--<< Counter Section Start >>-->
<section class="counter-section-2">
  <div class="container">
    <div class="counter-wrapper-2">
      <div class="counter-items wow fadeInUp" data-wow-delay=".3s">
        <div class="icon">
          <i class="flaticon-verified"></i>
        </div>
        <div class="content">
          <h2><span class="count">80</span>+</h2>
          <p>Projects Completed</p>
        </div>
      </div>
      <div class="counter-items wow fadeInUp" data-wow-delay=".5s">
        <div class="icon">
          <i class="flaticon-service"></i>
        </div>
        <div class="content">
          <h2><span class="count">50</span>+</h2>
          <p>Certified Experts</p>
        </div>
      </div>
      <div class="counter-items wow fadeInUp" data-wow-delay=".7s">
        <div class="icon">
          <i class="flaticon-customer-service-1"></i>
        </div>
        <div class="content">
          <h2><span class="count">73</span>+</h2>
          <p>Best-in-class NPS Score</p>
        </div>
      </div>
      <div class="counter-items wow fadeInUp" data-wow-delay=".9s">
        <div class="icon">
          <i class="flaticon-medal"></i>
        </div>
        <div class="content">
          <h2><span class="count">40</span>+</h2>
          <p>Technologies</p>
        </div>
      </div>
    </div>
  </div>
</section>

<!--<< Grow Section Start >>-->
<section class="grow-section section-padding section-bg-3">
  <div class="container">
    <div class="grow-wrapper">
      <div class="section-title style-2 col-md-12">
        <span class="text-white wow fadeInUp ft-twenty"
          >Crafting Experiences with Our Tech Blend Approach</span
        >
        <div class="content white-cnt">
          <p class="mt-3 mb-3">
            At Groot Software Solutions, we’re all about shaking things up in
            the tech world. Picture this: you got your tech mojo (that's the
            Engineering DNA) mixed with some serious user love (we call it
            Experience DNA). That’s our secret sauce for flipping the script on
            how businesses roll in the digital age.
          </p>
        </div>
      </div>
      <div class="title-section-area">
        <h3 class="text-white wow fadeInUp fade-text-call" data-wow-delay=".3s">
          Let's blend brilliance and revolutionize<br />
          your digital journey together.
        </h3>
        <a
          class="theme-btn theme-btn-2 hover-white wow fadeInUp"
          data-wow-delay=".5s"
          data-bs-toggle="modal"
          data-bs-target="#exampleModal"
        >
          <span>
            Get In Touch
            <i class="fas fa-chevron-right"></i>
          </span>
        </a>
      </div>
      <div class="circle-progress-bar-wrapper mbm-10"></div>
    </div>
  </div>
</section>

<!--<< Case Studies Start >>-->
<div class="marque-section pt-5">
  <div class="">
    <div class="marquee-inner to-left"></div>
  </div>
</div>

<!--<< Case Studies Section Start >>-->
<section class="case-study section-padding pt-0 mt-5">
  <div class="container">
    <div class="section-title style-2 text-center">
      <span class="wow fadeInUp span-caps">
        TURNING DREAMS INTO DIGITAL REALITY</span
      >
      <h2 class="wow fadeInUp" data-wow-delay=".3s">Success Stories</h2>
    </div>
  </div>
  <!-- <div class="case-study-wrapper">
    <div class="case-studies-carousel-active-2">
      <div class="case-study-items">
        <div class="case-study-image">
          <img src="assets/img/case-studies/05.jpg" alt="case-study-img" />
        </div>
        <div class="case-study-content">
          <h3>
            <a [routerLink]="['/boosting-financial-data-efficiency']"
              >Boosting Financial Data Efficiency</a
            >
          </h3>
          <p>Financial Services</p>
          <a
            [routerLink]="['/boosting-financial-data-efficiency']"
            class="icon"
          >
            <i class="fal fa-arrow-right"></i>
          </a>
        </div>
      </div>
      <div class="case-study-items">
        <div class="case-study-image">
          <img src="assets/img/case-studies/06.jpg" alt="case-study-img" />
        </div>
        <div class="case-study-content">
          <h3>
            <a [routerLink]="['/unlocking-digital-creativity']"
              >Unlocking Digital Creativity</a
            >
          </h3>
          <p>Digital Media & Entertainment</p>
          <a [routerLink]="['/unlocking-digital-creativity']" class="icon">
            <i class="fal fa-arrow-right"></i>
          </a>
        </div>
      </div>
      <div class="case-study-items">
        <div class="case-study-image">
          <img src="assets/img/case-studies/07.jpg" alt="case-study-img" />
        </div>
        <div class="case-study-content">
          <h3>
            <a [routerLink]="['/championing-healthcare-evolution']">
              Championing Healthcare Evolution
            </a>
          </h3>
          <p>Healthcare</p>
          <a [routerLink]="['/championing-healthcare-evolution']" class="icon">
            <i class="fal fa-arrow-right"></i>
          </a>
        </div>
      </div>
    </div>
  </div> -->
  <div class="case-study-wrapper">
    <div class="case-studies-carousel-active-2">
      <div class="case-study-items" *ngFor="let study of caseStudies">
        <div class="case-study-image">
          <img [src]="study.imageSrc" alt="case-study-img" />
        </div>
        <div class="case-study-content">
          <h3>
            <a [routerLink]="[study.link]">{{ study.title }}</a>
          </h3>
          <p>{{ study.category }}</p>
          <a [routerLink]="[study.link]" class="icon">
            <i class="fal fa-arrow-right"></i>
          </a>
        </div>
      </div>
    </div>
  </div>
</section>
<!--<< Team Section Start >>-->
<section class="team-section section-padding section-bg-3">
  <div class="dot-shape-2">
    <img
      src="assets/img/team/dot-shape-2.png"
      alt="shape-img"
      class="position-image-pos"
    />
  </div>
  <div class="container">
    <div class="section-title style-2 text-center">
      <span class="wow fadeInUp">American and Indian Leadership </span>
      <h2 class="text-white wow fadeInUp" data-wow-delay=".3s">
        Meet Our Leadership & Advisors
      </h2>
    </div>
  

    <div class="row justify-content-center">
      <div
        class="col-xl-3 col-lg-4 col-md-6 wow fadeInUp"
        *ngFor="let member of teamMembers; let i = index"
        [attr.data-wow-delay]="0.3 + i * 0.2 + 's'"
      >
        <div class="team-card-items">
          <div class="team-content">
            <a
              [routerLink]="['/', member.name.toLowerCase().replace(' ', '-')]"
            >
              <h4>{{ member.name }}</h4>
              <p>{{ member.position }}</p>
            </a>
          </div>
          <div class="team-image">
            <img [src]="member.imageSrc" alt="team-img" />
            <div class="social-profile">
              <a [href]="member.linkedin" target="_blank">
                <span class="plus-btn"><i class="fab fa-linkedin-in"></i></span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="team-button text-center mt-5 wow fadeInUp" data-wow-delay=".4s">
      <a
        [routerLink]="['/team']"
        routerLinkActive="active"
        class="theme-btn theme-btn-2 hover-white"
      >
        <span>
          Explore more team
          <i class="fas fa-chevron-right"></i>
        </span>
      </a>
    </div>
  </div>
</section>

<!--<< Cta Chat Section Start >>-->
<section class="cta-chat-section-2">
  <div class="container">
    <div class="cta-chat-wrapper style-2">
      <div class="chat-items wow fadeInUp col-md-9" data-wow-delay=".3s">
        <div class="icon">
          <i class="flaticon-chat"></i>
        </div>
        <div class="content">
          <h3>Experience Life at Groot Software Solutions</h3>
          <p>
            At Groot Software Solutions, we're more than just a company; we're a
            tight-knit global family fueled by innovation. Our team is made up
            of everyday people crafting exceptional digital experiences. Join us
            in a journey where we work hard, explore new horizons, and enjoy
            every moment along the way. Discover career opportunities at Groot
            today!
          </p>
        </div>
      </div>
      <a
        [routerLink]="['/enquiry']"
        class="theme-btn bg-white wow fadeInUp"
        data-wow-delay=".5s"
      >
        <span>
          Explore Careers

          <i class="fas fa-chevron-right"></i>
        </span>
      </a>
    </div>
  </div>
</section>

<!--<< News Section Start >>-->
<section class="news-section section-padding fix">
  <div class="container">
    <div class="title-section-area">
      <div class="section-title style-2">
        <span class="wow fadeInUp">Our News & Tips</span>
        <h2 class="wow fadeInUp" data-wow-delay=".3s">
          Upcoming News & <span>Blog</span>
        </h2>
      </div>
      <a
        [routerLink]="['/news']"
        class="theme-btn theme-btn-2 wow fadeInUp"
        data-wow-delay=".5s"
      >
        <span>
          Explore Our news
          <i class="fas fa-chevron-right"></i>
        </span>
      </a>
    </div>
    <!-- <div class="row">
      <div class="col-xl-4 col-lg-6 col-md-6 wow fadeInUp" data-wow-delay=".3s">
        <div class="single-news-items-2">
          <div
            class="news-image bg-cover"
            style="background-image: url('assets/img/news/06.jpg')"
          >
            <div class="post-date">
              <h4>
                25 <br />
                <span>Dec</span>
              </h4>
            </div>
          </div>
          <div class="news-content">
            <p>The Power Of Minimalism</p>
            <h4>
              <a [routerLink]="['/the-power-of-minimalism']">
                Simplifying Design To Enhance User Experiences
              </a>
            </h4>
            <a
              [routerLink]="['/the-power-of-minimalism']"
              class="link-btn link-btn-2"
            >
              <span>Read More</span>
              <i class="fas fa-chevron-right"></i>
            </a>
          </div>
        </div>
      </div>
      <div class="col-xl-4 col-lg-6 col-md-6 wow fadeInUp" data-wow-delay=".5s">
        <div class="single-news-items-2">
          <div
            class="news-image bg-cover"
            style="background-image: url('assets/img/news/07.jpg')"
          >
            <div class="post-date">
              <h4>
                28 <br />
                <span>Dec</span>
              </h4>
            </div>
          </div>
          <div class="news-content">
            <p>The Power Of User Experience</p>
            <h4>
              <a [routerLink]="['/the-power-of-user-experience']">
                Simplifying Design To Enhance User Experiences
              </a>
            </h4>
            <a
              [routerLink]="['/the-power-of-user-experience']"
              class="link-btn link-btn-2"
            >
              <span>Read More</span>
              <i class="fas fa-chevron-right"></i>
            </a>
          </div>
        </div>
      </div>
      <div class="col-xl-4 col-lg-6 col-md-6 wow fadeInUp" data-wow-delay=".7s">
        <div class="single-news-items-2">
          <div
            class="news-image bg-cover"
            style="background-image: url('assets/img/news/08.jpg')"
          >
            <div class="post-date">
              <h4>
                30 <br />
                <span>Dec</span>
              </h4>
            </div>
          </div>
          <div class="news-content">
            <p>Navigating AI</p>
            <h4>
              <a [routerLink]="['/navigating-ai']">
                Exploring Generative Vs Traditional Approaches
              </a>
            </h4>
            <a [routerLink]="['/navigating-ai']" class="link-btn link-btn-2">
              <span>Read More</span>
              <i class="fas fa-chevron-right"></i>
            </a>
          </div>
        </div>
      </div>
    </div> -->
    <div class="row">
      <div
        class="col-xl-4 col-lg-6 col-md-6 wow fadeInUp"
        *ngFor="let item of newsItems; let i = index"
        [attr.data-wow-delay]="0.3 + i * 0.2 + 's'"
      >
        <div class="single-news-items-2">
          <div
            class="news-image bg-cover"
            [style.backgroundImage]="'url(' + item.imageSrc + ')'"
          >
            <div class="post-date">
              <h4>
                {{ item.date.day }} <br />
                <span>{{ item.date.month }}</span>
              </h4>
            </div>
          </div>
          <div class="news-content">
            <p>{{ item.content }}</p>
            <h4>
              <a [routerLink]="[item.link]">{{ item.title }}</a>
            </h4>
            <a [routerLink]="[item.link]" class="link-btn link-btn-2">
              <span>Read More</span>
              <i class="fas fa-chevron-right"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<!--<< Contact Section Start >>-->
<section
  class="contact-section-2 fix section-padding bg-cover"
  style="background-image: url('assets/img/contact-bg.jpg')"
>
  <div class="contact-image">
    <img src="assets/img/contact-2.png" alt="contact-img" />
  </div>
  <div class="container">
    <div class="contact-wrapper-2">
      <div class="row align-items-center">
        <div class="col-lg-6">
          <div class="contact-content">
            <div class="section-title style-2">
              <span class="wow fadeInUp">Get in Touch</span>
              <h2 class="wow fadeInUp" data-wow-delay=".3s">
                Schedule A Free Consultation
              </h2>
            </div>
            <p class="mt-4 mt-md-0 wow fadeInUp" data-wow-delay=".5s">
              Top American businesses accelerate their tech roadmaps with us.
              <br />See how we can help you.
            </p>
            <div class="contact-info-area">
              <div
                class="contact-info-items mb-4 wow fadeInUp"
                data-wow-delay=".7s"
              >
                <div class="icon">
                  <i class="far fa-phone"></i>
                </div>
                <div class="content">
                  <p>Call For Inquiry</p>
                  <h3><a href="tel:+01762460300">0176-2460300</a></h3>
                </div>
              </div>
              <div
                class="contact-info-items mb-4 wow fadeInUp"
                data-wow-delay=".8s"
              >
                <div class="icon">
                  <i class="fal fa-envelope"></i>
                </div>
                <div class="content">
                  <p>Send Us Email</p>
                  <h3>
                    <a href="mailto:info@grootsoftwares.com"
                      >info&#64;grootsoftwares.com</a
                    >
                  </h3>
                </div>
              </div>
              <div class="contact-info-items wow fadeInUp" data-wow-delay=".9s">
                <div class="icon">
                  <i class="fas fa-map-marker-alt"></i>
                </div>
                <div class="content">
                  <p>Location</p>
                  <h3>
                    Sushma Infinium, 3rd Floor, Zirakpur(Chandigarh), Punjab,
                    140603
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6 mt-5 mt-lg-0">
          <div class="contact-box">
            <div class="contact-title">
              <h3 class="wow fadeInUp" data-wow-delay=".3s">
                Need Help For Project!
              </h3>
              <p class="wow fadeInUp" data-wow-delay=".5s">
                We are ready to help your next projects, let’s work together
              </p>
            </div>
            <div class="contact-form-items">
              <app-commonserviceform></app-commonserviceform>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- Modal -->
<div
  class="modal fade"
  id="exampleModal"
  tabindex="-1"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered">
    <i class="fal fa-x me-2"></i>
    <div class="modal-content">
      <div class="contact-main-wrapper">
        <button
          type="button"
          class="btn-close close-modals"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
        <div class="contact-form-items">
          <div class="contact-title">
            <h3 class="wow fadeInUp" data-wow-delay=".3s">Fill Up The Form</h3>
            <p class="wow fadeInUp" data-wow-delay=".5s">
              Your email address will not be published. Required fields are
              marked *
            </p>
          </div>
          <form
            action="contact.php"
            id="contact-form"
            method="POST"
            [formGroup]="enquiryForm"
            (ngSubmit)="onSubmitNew(enquiryForm)"
          >
            <div class="row g-4">
              <div class="col-lg-12 wow fadeInUp" data-wow-delay=".3s">
                <div class="form-clt">
                  <input
                    type="text"
                    name="name"
                    id="name"
                    placeholder="Your Name*"
                    formControlName="name"
                  />
                  <div class="icon">
                    <i class="fal fa-user"></i>
                  </div>
                </div>
                <div *ngIf="submitted && f1.name.errors" class="text-danger">
                  <div *ngIf="f1.name.errors.required">
                    Your name is required
                  </div>
                  <div
                    *ngIf="
                      f1.name.errors.notOnlyWhitespace &&
                      !f1.name.errors.required
                    "
                  >
                    Your name is required
                  </div>
                </div>
              </div>
              <div class="col-lg-12 wow fadeInUp" data-wow-delay=".5s">
                <div class="form-clt">
                  <input
                    type="text"
                    name="email"
                    id="email"
                    placeholder="Email Address*"
                    formControlName="email"
                  />
                  <div class="icon">
                    <i class="fal fa-envelope"></i>
                  </div>
                </div>
                <div *ngIf="submitted && f1.email.errors" class="text-danger">
                  <div *ngIf="f1.email.errors.required">Email is required</div>
                  <div
                    *ngIf="f1.email.errors.email && !f1.email.errors.required"
                  >
                    Invalid email
                  </div>
                </div>
              </div>
              <div class="col-lg-12 wow fadeInUp" data-wow-delay=".7s">
                <div class="form-clt">
                  <textarea
                    name="message"
                    id="message"
                    placeholder="Enter Your Message Here*"
                    formControlName="message"
                  ></textarea>
                  <div class="icon">
                    <i class="fal fa-edit"></i>
                  </div>
                </div>
                <div *ngIf="submitted && f1.message.errors" class="text-danger">
                  <div *ngIf="f1.message.errors.required">
                    Message is required
                  </div>
                  <div
                    *ngIf="
                      f1.message.errors.notOnlyWhitespace &&
                      !f1.message.errors.required
                    "
                  >
                    Message is required
                  </div>
                </div>
              </div>

              <div class="col-lg-12 wow fadeInUp" data-wow-delay=".7s">
                <div class="form-clt">
                  <re-captcha
                    (resolved)="resolved($event)"
                    siteKey="{{ environment.recaptcha.siteKey }}"
                    formControlName="recaptcha"
                  ></re-captcha>
                </div>
                <div
                  *ngIf="submitted && f1.recaptcha.errors"
                  class="text-danger"
                >
                  <div *ngIf="f1.recaptcha.errors.required">
                    Please verify you are human
                  </div>
                </div>
              </div>
              <div class="col-lg-12 wow fadeInUp" data-wow-delay=".8s">
                <button type="submit" class="theme-btn" [disabled]="disabled">
                  <span
                    ><i class="fal fa-paper-plane me-2"></i>Get In Touch</span
                  >
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
