<div
  class="breadcrumb-wrapper section-padding bg-cover mb-0 size-first"
  style="background-image: url('../../../assets/img/bg-4.jpg')"
>
  <div class="container">
    <div class="page-heading">
      <h1 class="wow fadeInUp" data-wow-delay=".3s">Become A Partner</h1>
    </div>
  </div>
</div>

<section class="grow-section section-padding section-bg-3">
  <div class="container">
    <div class="grow-wrapper">
      <div class="section-title style-2 col-md-12">
        <div class="content white-cnt">
          <h3
            class="text-white wow fadeInUp fade-text-call"
            data-wow-delay=".3s"
          >
            Unlock Unlimited Earnings & Lifetime Commissions
          </h3>
        </div>
      </div>
      <div class="title-section-area">
        <p class="mt-3 mb-3 pe-5 w-75 text-white">
          Start earning recurring commissions on every successful referral
          throughout your first year of partnership. No minimum commitments and
          a non-binding agreement make getting started simple.
        </p>
        <a
          [routerLink]="['/enquiry']"
          class="theme-btn theme-btn-2 hover-white wow fadeInUp"
          data-wow-delay=".5s"
          data-bs-toggle="modal"
          data-bs-target="#exampleModal"
        >
          <span>
            Become a Partner
            <i class="fas fa-chevron-right"></i>
          </span>
        </a>
      </div>
      <div class="circle-progress-bar-wrapper mbm-10"></div>
    </div>
  </div>
</section>
<section class="counter-section-2 style-margin">
  <div class="container">
    <div class="counter-wrapper-2">
      <div class="counter-items wow fadeInUp" data-wow-delay=".3s">
        <div class="icon">
          <i class="flaticon-verified"></i>
        </div>
        <div class="content">
          <h2><span class="count">80</span>+</h2>
          <p>Projects Completed</p>
        </div>
      </div>
      <div class="counter-items wow fadeInUp" data-wow-delay=".5s">
        <div class="icon">
          <i class="flaticon-service"></i>
        </div>
        <div class="content">
          <h2><span class="count">50</span>+</h2>
          <p>Certified Experts</p>
        </div>
      </div>
      <div class="counter-items wow fadeInUp" data-wow-delay=".7s">
        <div class="icon">
          <i class="flaticon-customer-service-1"></i>
        </div>
        <div class="content">
          <h2><span class="count">73</span></h2>
          <p>Best-in-class NPS Score</p>
        </div>
      </div>
      <div class="counter-items wow fadeInUp" data-wow-delay=".9s">
        <div class="icon">
          <i class="flaticon-medal"></i>
        </div>
        <div class="content">
          <h2><span class="count">40</span>+</h2>
          <p>Technologies</p>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- -------- start Separate Section—---------  -->
<section>
  <div
    class="container text-center p-5 mobile-customs-set"
    style="background-color: ghostwhite; border-radius: 30px; margin-top: 15%"
  >
    <p>
      <b
        ><i
          >"Does your company offer products or services to businesses,
          agencies, or freelancers? Partner with us to generate additional
          revenue for your business. Everyone is Hiring Engineers. Checkout Who
          Can Be an Ideal Partner for Groot Software Solutions? Our program is
          designed for strategic alliances. Let’s craft a mutually beneficial
          partnership. Find out if you’d be a great fit for Groot Software
          Solutions. "</i
        ></b
      >
    </p>

    <div class="card-group mt-5" style="gap: 30px">
      <div class="card card-radius" style="border-radius: 15px">
        <img
          class="card-img-top"
          src="../../../assets/img/project/it-consulting.png"
          alt="Card image cap"
        />
        <div class="card-body">
          <h5 class="card-title">Business & IT Consultants</h5>
          <p class="card-text">
            Your expertise in identifying new markets, insights about industry
            trends, and emerging technologies can help us build a value-centric
            partnership.
          </p>
        </div>
      </div>
      <div class="card card-radius" style="border-radius: 15px">
        <img
          class="card-img-top"
          src="../../../assets/img/project/Marketing.jpg"
          alt="Card image cap"
        />
        <div class="card-body">
          <h5 class="card-title">Marketing Agencies</h5>
          <p class="card-text">
            Leverage your strong online presence to find referral partners in
            your client base who match our potential clients and join our
            referral partner program as valuable allies.
          </p>
        </div>
      </div>
      <div class="card card-radius" style="border-radius: 15px">
        <img
          class="card-img-top"
          src="../../../assets/img/project/Clients.jpg"
          alt="Card image cap"
        />
        <div class="card-body">
          <h5 class="card-title">Individuals with Potential Clients</h5>
          <p class="card-text">
            Spread the word among your existing customers, partners, and wider
            network, encouraging them to avail IT software development solutions
            by partnering with us.
          </p>
        </div>
      </div>
    </div>

    <div style="color: black; margin-top: 30px">
      <p>
        <b
          ><i>
            "Have a great network of businesses, agencies, or freelancers?<br />
            That's all you need to get started! Earn a Recurring Commission on
            Each Referral Turned Lead "</i
          ></b
        >
      </p>
    </div>
  </div>
</section>
<section class="service-details fix section-padding pt-5 padding-set">
  <div class="container">
    <div class="service-details-wrapper">
      <div class="row">
        <div class="col-12 col-lg-8">
          <div class="seperation mt-5 mb-4"></div>
          <div class="service-details-items">
            <div class="details-content content-req">
              <h3 class="mt-3">What Benefits Are in Store for You?</h3>
              <p class="mt-3"><b>With your value, your business grows!</b></p>
              <ul class="mt-3">
                <li class="text-list">
                  Derive Recurring Commission: Earn lucrative commissions on
                  every lead that converts to a customer in the 1st year.
                </li>
                <li class="text-list">
                  Guaranteed Network Expansion: Grow your network with us and
                  gain new business opportunities.
                </li>
                <li class="text-list">
                  Multiply Business Value: Provide extra value to your network
                  by offering a broader range of services.
                </li>
              </ul>
            </div>
          </div>
          <!-- <div class="FQA mt-5">
            <h3>Frequently Asked Questions</h3>
            <div class="faq-content">
              <div class="faq-accordion">
                <div class="accordion" id="accordion">
                  <div class="accordion-item wow fadeInUp" data-wow-delay=".3s">
                    <h4 class="accordion-header">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#faq1"
                        aria-expanded="false"
                        aria-controls="faq1"
                      >
                        What does the Groot Software Solutions Partner Program
                        offer?
                      </button>
                    </h4>
                    <div
                      id="faq1"
                      class="accordion-collapse collapse"
                      data-bs-parent="#accordion"
                    >
                      <div class="accordion-body">
                        The Groot Software Solutions Partner Program provides an
                        exclusive chance to earn lifelong commissions and
                        rewards by referring your network to Groot Software
                        Solutions. We are happy to have a conversation with
                        anyone in your network who could be a fit for our
                        services.
                        <p class="mt-3">
                          <b>Some good referral opportunities are: </b>
                        </p>
                        <ul class="mt-3">
                          <li class="text-list">
                            People or businesses that need support with a
                            software development project
                          </li>
                          <li class="text-list">
                            Companies that are struggling to fill IT-related
                            positions
                          </li>
                          <li class="text-list">
                            Businesses looking to outsource their software
                            development to a Nearshore/Offshore provider.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item wow fadeInUp" data-wow-delay=".5s">
                    <h4 class="accordion-header">
                      <button
                        class="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#faq2"
                        aria-expanded="true"
                        aria-controls="faq2"
                      >
                        What are Groot Software Solution’s area of expertise?
                      </button>
                    </h4>
                    <div
                      id="faq2"
                      class="accordion-collapse collapse show"
                      data-bs-parent="#accordion"
                    >
                      <div class="accordion-body">
                        Custom Software Development, Software Testing & QA,
                        Cloud Infrastructure & Devops, Mobile & Web Development,
                        Maintenance & Support, Blockchain Consulting, Data
                        Science, Internet of Things, UI/UX Design, E Commerce,
                        Cybersecurity, and many others.
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item wow fadeInUp" data-wow-delay=".7s">
                    <h4 class="accordion-header">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#faq3"
                        aria-expanded="false"
                        aria-controls="faq3"
                      >
                        What are my responsibilities as a partner?
                      </button>
                    </h4>
                    <div
                      id="faq3"
                      class="accordion-collapse collapse"
                      data-bs-parent="#accordion"
                    >
                      <div class="accordion-body">
                        Your role as a partner involves referring your network
                        to Groot Software Solutions, aiding in their onboarding
                        process, and encouraging them to opt for premier
                        technology services.
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item wow fadeInUp" data-wow-delay=".9s">
                    <h4 class="accordion-header">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#faq4"
                        aria-expanded="false"
                        aria-controls="faq4"
                      >
                        Is there a fee to join the partner program?
                      </button>
                    </h4>
                    <div
                      id="faq4"
                      class="accordion-collapse collapse"
                      data-bs-parent="#accordion"
                    >
                      <div class="accordion-body">
                        No, the Groot Software Solutions Partner Program is
                        entirely free. There are no charges or joining fees.
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item wow fadeInUp" data-wow-delay=".9s">
                    <h4 class="accordion-header">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#faq5"
                        aria-expanded="false"
                        aria-controls="faq5"
                      >
                        When will I receive my earnings?
                      </button>
                    </h4>
                    <div
                      id="faq5"
                      class="accordion-collapse collapse"
                      data-bs-parent="#accordion"
                    >
                      <div class="accordion-body">
                        Your earnings will be credited during the first week of
                        the following month of the billing.
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item wow fadeInUp" data-wow-delay=".9s">
                    <h4 class="accordion-header">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#faq6"
                        aria-expanded="false"
                        aria-controls="faq6"
                      >
                        Where can I find more information?
                      </button>
                    </h4>
                    <div
                      id="faq6"
                      class="accordion-collapse collapse"
                      data-bs-parent="#accordion"
                    >
                      <div class="accordion-body">
                        For further inquiries, fill out the form above, WhatsApp
                        us at +91-6283282804, or drop us an email at
                        <b>info&#64;grootsoftwares.com</b>.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> -->

          <div class="FQA mt-5">
            <h3>Frequently Asked Questions</h3>
            <div class="faq-content">
              <div class="faq-accordion">
                <div class="accordion" id="accordion">
                  <div
                    class="accordion-item wow fadeInUp"
                    *ngFor="let faq of faqs; let i = index"
                    [attr.data-wow-delay]="'.3s' + i * 0.2 + 's'"
                  >
                    <h4 class="accordion-header">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        [attr.data-bs-target]="'#faq' + (i + 1)"
                        aria-expanded="false"
                        [attr.aria-controls]="'faq' + (i + 1)"
                      >
                        {{ faq.question }}
                      </button>
                    </h4>
                    <div
                      [id]="'faq' + (i + 1)"
                      class="accordion-collapse collapse"
                      [attr.aria-labelledby]="'faq' + (i + 1)"
                      data-bs-parent="#accordion"
                    >
                      <div class="accordion-body">
                        {{ faq.answer }}
                        <p class="mt-3" *ngIf="faq.additionalInfo">
                          <b>Some good referral opportunities are:</b>
                        </p>
                        <ul class="mt-3" *ngIf="faq.additionalInfo">
                          <li
                            class="text-list"
                            *ngFor="let info of faq.additionalInfo"
                          >
                            {{ info }}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-4 mt-4">
          <div class="main-sidebar">
            <div class="single-sidebar-widget">
              <div class="wid-title">
                <h3>Contact us to kickstart your innovation journey</h3>
              </div>
              <app-commonenquiryform></app-commonenquiryform>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
